import "./jquery.js";

export function load_upload() {
  $(".browse").click(function () {
    $(this).parent().find("input:file").click();
  });

  $("input:file").on("change", function (e: any) {
    const file = e.target.files[0],
      reader = new FileReader();

    $("input:text", $(e.target).parent()).val(file.name);

    reader.addEventListener(
      "load",
      function () {
        $(".image-file-input").attr("src", String(reader.result));
      },
      false,
    );

    reader.readAsDataURL(file);
  });
}

load_upload();

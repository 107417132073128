import "./jquery.js";
import flatpickr from "flatpickr";

export function load_datepicker() {
  flatpickr(".datepicker", {});

  $(".add").click(function () {
    $('input[type="date"]').remove();
    setTimeout(function () {
      flatpickr(".datepicker", {});
    }, 100);
  });
}

load_datepicker();

import Dropzone from "dropzone";

export function load_dropzone() {
  Dropzone.autoDiscover = false;

  if (document.querySelector(".dropzone")) {
    $.getJSON("/wfm/users.json", ({ documents }) => {
      // const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")

      const dropzone = new Dropzone(".dropzone", {
        acceptedFiles: "image/*,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf",
        clickable: true,
        filesizeBase: 1000,
        maxFilesize: 25,
        uploadMultiple: true,
        parallelUploads: 1,
        paramName: "file",
        thumbnailHeight: 120,
        thumbnailWidth: 120,
      });

      dropzone.on("sending", function (file) {
        const ext = file.name.split(".").pop();
        const id = $("#upload_parent").val();

        let link;

        switch (ext) {
          case "doc":
          case "docx":
            link = "/images/word.png";
            $("#upload_icon").val("word");
            break;
          case "xls":
          case "xlsx":
            link = "/images/xls.png";
            $("#upload_icon").val("excel");
            break;
          case "ppt":
          case "pptx":
            link = "/images/ppt.png";
            $("#upload_icon").val("powerpoint");
            break;
          case "pdf":
            link = "/images/pdf.png";
            $("#upload_icon").val("pdf");
            break;
          case "jpg":
          case "jpeg":
          case "png":
            $("#upload_icon").val("image");
            break;
          default:
            $("#upload_icon").val("file");
        }

        if (documents) {
          let data = documents.find({ id: id, type: "file" });

          if (data) {
            $("#upload_parent").val(data.parent);
          }
        }

        $("#upload_text").val(file.name);

        $(file.previewElement)
          .find(".dz-image")
          .css({
            background: "url('" + link + "') no-repeat center",
            "background-size": "cover",
          });
      });

      // Download Files
      // $('#download-files').click(function() {
      // 	let count = 0;
      // 	$('.check-one:checked').each(function() {
      // 		const file = _.find(documents, {'id': (this as HTMLInputElement).value });
      // 		const hiddenIFrameID = 'hiddenDownloader' + count++;
      // 		const iframe = document.createElement('iframe');
      // 		iframe.id = hiddenIFrameID;
      // 		iframe.style.display = 'none';
      // 		document.body.appendChild(iframe);
      // 		iframe.src = file.url;
      // 	});
      // });

      // Download single file
      // $('.download-file').click(function() {
      // 	const file = _.find(documents, {'id': this.id });
      // 	const hiddenIFrameID = 'hiddenDownloader0';
      // 	const iframe = document.createElement('iframe');
      // 	iframe.id = hiddenIFrameID;
      // 	iframe.style.display = 'none';
      // 	document.body.appendChild(iframe);
      // 	iframe.src = file.url;
      // });
    });
  }
}

load_dropzone();

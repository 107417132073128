import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  DoughnutController,
  LineController,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { OrgChart } from "d3-org-chart";
import "./jquery.js";

export function load_dashboard() {
  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    DoughnutController,
    LineController,
    CategoryScale,
    LinearScale,
  );

  $.getJSON("/wfm/users.json", ({ departments, users }) => {
    const date = new Date(),
      month = date.getMonth(),
      year = date.getFullYear(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

    let labels = [];

    for (let i = 1; i <= months.length; i++) {
      if (month + i < 12) {
        labels.push(months[month + i] + " " + (year - 1));
      } else {
        labels.push(months[month + i - 12] + " " + year);
      }
    }

    const validateDate = (joinDate: any, splitLabel: any) => {
      let validate = false;

      if (joinDate[0] < splitLabel[1]) {
        validate = true;
      } else {
        if (
          joinDate[0] == splitLabel[1] &&
          Number(joinDate[1]) <= months.indexOf(splitLabel[0]) + 1
        ) {
          validate = true;
        }
      }

      return validate;
    };

    const getData = (labels: any) => {
      const list = [];

      for (let i = 0; i < labels.length; i++) {
        list.push(
          users.filter((p: any) => {
            let result = false;

            if (p.joining_date != null) {
              const splitLabel = labels[i].split(" "),
                joinDate = p.joining_date.split("-"),
                terminationDate =
                  p.termination_date == null
                    ? null
                    : p.termination_date.split("-");

              if (terminationDate == null) {
                result = validateDate(joinDate, splitLabel);
              } else {
                if (
                  terminationDate[0] >= splitLabel[1] &&
                  Number(terminationDate[1]) > months.indexOf(splitLabel[0]) + 1
                ) {
                  result = validateDate(joinDate, splitLabel);
                }
              }
            }

            return result;
          }).length,
        );
      }

      return list;
    };

    const lineConfig = {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            backgroundColor: "#0694a2",
            borderColor: "#0694a2",
            data: getData(labels),
            fill: false,
            tension: 0.4,
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          x: {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Month",
            },
          },
          y: {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Value",
            },
          },
        },
      },
    };

    const lineCtx = document.getElementById("line");
    // @ts-expect-error
    new Chart(lineCtx, lineConfig);

    let data = {
      datasets: [
        {
          data: departments.map((d: any) => {
            return users.filter((p: any) => {
              return p.department_id == d.id;
            }).length;
          }),
          backgroundColor: [
            "#0694a2",
            "#1c64f2",
            "#7e3af2",
            "#d97706",
            "#ea580c",
          ],
          borderWidth: 0,
          hoverOffset: 4,
        },
      ],
      labels: departments.map((d: any) => d.name),
    };

    const pieConfig = {
      type: "doughnut",
      data: data,
      options: {
        responsive: true,
        cutout: "80%",
        legend: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      },
    };

    const pieCtx = document.getElementById("pie");
    // @ts-expect-error
    new Chart(pieCtx, pieConfig);

    const barConfig = {
      type: "bar",
      data: {
        labels: ["0-1yr", "2-3yrs", "4-6yrs", "7-9yrs", "10yrs+"],
        datasets: [
          {
            label: "Shoes",
            backgroundColor: "#0694a2",
            borderWidth: 2,
            data: ["0-1", "2-3", "4-6", "7-9", "10-100"].map((y) => {
              return users.filter((p: any) => {
                if (p.joining_date != null) {
                  const range = y.split("-"),
                    splitDate = p.joining_date.split("-"),
                    years = date.getFullYear() - Number(splitDate[0]);

                  return Number(range[0]) <= years && Number(range[1]) >= years;
                } else {
                  return false;
                }
              }).length;
            }),
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
      },
    };

    const barsCtx = document.getElementById("bars");
    // @ts-expect-error
    new Chart(barsCtx, barConfig);
  });
}

export function load_org_chart() {
  const getImage = ({ avatar }: any) => {
    if (avatar == null) {
      return "/images/sample_avatar.jpg";
    } else {
      return avatar;
    }
  };

  const setTemplateCompany = (company: any) => {
    return [
      `<div class="bg-white ring-1 ring-black ring-opacity-5 dark:bg-gray-800 px-5 h-full overflow-hidden rounded-lg shadow-lg flex items-center justify-center">`,
      `<div class="text-center font-semibold">`,
      `<p class="text-xl text-primary-700 dark:text-primary-300">${company.name}</p>`,
      `<p class="text-xs uppercase text-gray-500">Company</p>`,
      `</div>`,
      `</div>`,
    ].join("\n");
  };

  const setTemplateDepartment = (boss: any, department: any) => {
    return [
      `<a phx-click="frontend_navigate" phx-value-id="${boss.id}">`,
      `<div class="bg-white ring-1 ring-black ring-opacity-5 dark:bg-gray-800 px-5 h-full overflow-hidden rounded-lg shadow-lg flex items-center">`,
      `<div class="font-semibold flex items-center space-x-3">`,
      `<img src=${getImage(boss)} class="rounded-full w-12 flex-shrink-0">`,
      `<div class="space-y-1">`,
      `<p class="text-lg text-primary-700 leading-5 dark:text-primary-300">${boss.first_name} ${boss.last_name}</p>`,
      `<p class="text-xs uppercase text-gray-500">Boss of ${department.name} Team</p>`,
      `</div>`,
      `</div>`,
      `</div>`,
      `</a>`,
    ].join("\n");
  };

  const setTemplateUser = (user: any) => {
    return [
      `<a phx-click="frontend_navigate" phx-value-id="${user.id}">`,
      `<div class="bg-white ring-1 ring-black ring-opacity-5 dark:bg-gray-800 px-5 h-full overflow-hidden rounded-lg shadow-lg flex items-center">`,
      `<div class="font-semibold flex items-center space-x-3">`,
      `<img src=${getImage(user)} class="rounded-full w-12 flex-shrink-0">`,
      `<div class="space-y-1">`,
      `<p class="text-lg text-primary-700 leading-5 dark:text-primary-300">${user.first_name} ${user.last_name}</p>`,
      `<p class="text-xs uppercase text-gray-500">${
        user.job_title || "No Job Title"
      }</p>`,
      `</div>`,
      `</div>`,
      `</div>`,
      `</a>`,
    ].join("\n");
  };

  const setCompany = (company: any) => {
    return {
      nodeId: company.id,
      template: setTemplateCompany(company),
      expanded: false,
    };
  };

  const setDepartment = (boss: any, department: any, parentId: any) => {
    return {
      nodeId: department.id,
      parentNodeId: parentId,
      template: setTemplateDepartment(boss, department),
      expanded: false,
    };
  };

  const setUser = (user: any, parentId: any) => {
    return {
      nodeId: user.id,
      parentNodeId: parentId,
      template: setTemplateUser(user),
      expanded: false,
    };
  };

  $.getJSON("/wfm/org-chart/users.json", ({ company, departments, users }) => {
    let data = [];

    data.push(setCompany(company));
    departments.map((department: any) => {
      const boss = users.find((user: any) => user.id == department.boss_id);
      const members = users.filter(
        (user: any) =>
          user.id != boss.id && user.department_id == department.id,
      );

      data.push(setDepartment(boss, department, company.id));
      members.map((member: any) => {
        data.push(setUser(member, department.id));
      });
    });

    new OrgChart()
      .container(".org-chart")
      .data(data)
      .nodeHeight(() => 110)
      .nodeWidth(() => 270)
      .compactMarginBetween(() => 50)
      .buttonContent(({ node }: any) => {
        return [
          `<div class="flex bg-primary-600 rounded-lg px-1 m-auto items-center justify-center">`,
          `<span class="text-primary-100">`,
          node.children
            ? `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
          							<path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
          						</svg>`
            : `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
          							<path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
          						</svg>`,
          `</span>`,
          `</div>`,
        ].join("\n");
      })
      .nodeContent(({ data }: any) => {
        return data.template;
      })
      .render();
  });
}

import Pickr from "@simonwep/pickr";

export function load_colorpicker() {
  const colorpicker = document.querySelectorAll(".color-picker");

  for (let i = 0; i < colorpicker.length; i++) {
    let defaultColorPick = document.getElementById(colorpicker[i].id);
    let defaultColor;

    if (defaultColorPick) {
      defaultColor = defaultColorPick.dataset.color;
    }

    const pickr = Pickr.create({
      el: ".color-picker",
      default: defaultColor,
      theme: "nano",
      swatches: [
        "#ac725e",
        "#d06b64",
        "#f83a22",
        "#fa573c",
        "#ff7537",
        "#ffad46",
        "#42d692",
        "#16a765",
        "#7bd148",
        "#b3dc6c",
        "#fbe983",
        "#fad165",
        "#92e1c0",
        "#9fe1e7",
        "#9fc6e7",
        "#4986e7",
        "#9a9cff",
        "#b99aff",
        "#c2c2c2",
        "#cabdbf",
        "#cca6ac",
        "#f691b2",
        "#cd74e6",
        "#a47ae2",
      ],
      components: {
        preview: true,
        hue: true,
        interaction: {
          input: true,
          save: true,
        },
      },
    });

    pickr.on("save", (color: any) => {
      const colorPick = document.querySelector(`.${colorpicker[i].id} input`);

      if (colorPick) {
        colorPick.setAttribute("value", color.toHEXA().toString());
      }

      pickr.hide();
    });
  }
}

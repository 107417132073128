import "./jquery.js";
import "repeatable-fields";

export function load_repeater() {
  $(".repeater").each(function () {
    // @ts-expect-error
    $(this).repeatable_fields();
  });

  $(".remove").click(function () {
    const id = $(this).data("id");
    $(`#${id}_id`).remove();
  });
}

load_repeater();

import * as Sentry from "@sentry/browser";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://52a787e599874edb849707099c1a4357@o48945.ingest.sentry.io/1831666",
    environment: "prod",
    denyUrls: [
      // Cloudflare Zaraz
      /app\.hezum\.com\/cdn-cgi\/zaraz\/s.js/i,
    ],
  });
}

import "phoenix_html";
import "./alpine.js";
import { load_calendar, load_wallchart } from "./calendar.js";
import { load_dashboard, load_org_chart } from "./chart.js";
import { load_colorpicker } from "./colorpicker.js";
import { load_datepicker } from "./datepicker.js";
import { load_dropzone } from "./dropzone.js";
import { load_repeater } from "./repeater.js";
import { load_upload } from "./upload.js";

import { Socket } from "phoenix";
import { LiveSocket } from "phoenix_live_view";

const metaTag = document.querySelector("meta[name='csrf-token']");
const csrfToken = metaTag ? metaTag.getAttribute("content") : metaTag;

let Hooks = {
  Calendar: {
    mounted() {
      load_calendar();
    },
  },
  Dashboard: {
    mounted() {
      load_dashboard();
    },
  },
  Form: {
    mounted() {
      load_colorpicker();
      load_datepicker();
      load_dropzone();
      load_repeater();
      load_upload();
    },
  },
  OrgChart: {
    mounted() {
      load_org_chart();
    },
  },
  Wallchart: {
    mounted() {
      load_wallchart();
    },
  },
};

let liveSocket = new LiveSocket("/live", Socket, {
  params: { _csrf_token: csrfToken },
  hooks: Hooks,
});

window.addEventListener("phx:refresh_wallchart", load_wallchart);
window.addEventListener("phx:refresh_calendar", load_calendar);

liveSocket.connect();

(<any>window).liveSocket = liveSocket;

import "./jquery.js";
import Calendar from "js-year-calendar";
import flatpickr from "flatpickr";

export function load_calendar() {
  const calendarDiv = document.querySelector("#calendar");
  const searchParams = new URLSearchParams(window.location.search);
  const date = new Date();
  const userId = calendarDiv ? (calendarDiv as HTMLElement).dataset.userId : "";
  const currentYear = parseInt(
    searchParams.get("year") || `${date.getFullYear()}`,
  );
  const dataLeaves: any[] = [];

  $.getJSON(
    `/wfm/time-off/calendar/${userId}/absence.json?year=${currentYear}`,
    ({ absences, holidays, user }) => {
      absences.forEach((absence: any) => {
        dataLeaves.push({
          id: absence.id,
          name: absence.leave_type.name,
          color: absence.leave_type.color,
          startDate: new Date(absence.start_date),
          startTime: absence.start_time,
          endDate: new Date(absence.end_date),
          endTime: absence.end_time,
          isApproved: absence.is_approved,
        });
      });

      holidays.forEach((holiday: any) => {
        dataLeaves.push({
          id: holiday.id,
          type: "Public Holiday",
          name: holiday.name,
          startDate: new Date(holiday.observed),
          endDate: new Date(holiday.observed),
        });
      });

      if (user.birthday) {
        const birthdate = new Date(user.birthday);
        birthdate.setFullYear(currentYear);

        dataLeaves.push({
          type: "Birthday",
          name: "Birthday",
          startDate: new Date(birthdate),
          endDate: new Date(birthdate),
        });
      }

      const calendar = new Calendar("#calendar", {
        loadingTemplate: "",
        style: "custom",
        allowOverlap: false,
        enableRangeSelection: true,
        selectRange: (e) => {
          flatpickr('input[name="calendar[start_date]"]', {
            defaultDate: e.startDate,
          });
          flatpickr('input[name="calendar[end_date]"]', {
            defaultDate: e.endDate,
          });

          window.dispatchEvent(new CustomEvent("book"));
        },
        dataSource: dataLeaves,
        customDayRenderer: (element, date) => {
          if (date.toDateString() == new Date().toDateString()) {
            $(element).parent().addClass("today");
          }
        },
        customDataSourceRenderer: (element, date, absences) => {
          const absence = absences[0];
          const color = absence.color;
          let background;

          if (
            absence.startDate.toDateString() == date.toDateString() &&
            absence.endDate.toDateString() == date.toDateString()
          ) {
            if (absence.type == "Birthday") {
              element.setAttribute("hidden", "");
              element.insertAdjacentHTML(
                "afterend",
                `<svg class="h-4 w-4 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="currentColor"><path d="M448 384c-28.02 0-31.26-32-74.5-32-43.43 0-46.825 32-74.75 32-27.695 0-31.454-32-74.75-32-42.842 0-47.218 32-74.5 32-28.148 0-31.202-32-74.75-32-43.547 0-46.653 32-74.75 32v-80c0-26.5 21.5-48 48-48h16V112h64v144h64V112h64v144h64V112h64v144h16c26.5 0 48 21.5 48 48v80zm0 128H0v-96c43.356 0 46.767-32 74.75-32 27.951 0 31.253 32 74.75 32 42.843 0 47.217-32 74.5-32 28.148 0 31.201 32 74.75 32 43.357 0 46.767-32 74.75-32 27.488 0 31.252 32 74.5 32v96zM96 96c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40zm128 0c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40zm128 0c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40z"/></svg>`,
              );
            } else if (absence.type == "Public Holiday") {
              element.setAttribute("hidden", "");
              element.insertAdjacentHTML(
                "afterend",
                `<svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 mx-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>`,
              );
            } else if (
              absence.startTime == "Morning" &&
              absence.endTime == "Lunchtime"
            ) {
              background =
                "linear-gradient(135deg, " +
                color +
                ", " +
                color +
                " 49%, transparent 51%, transparent)";
            } else if (
              absence.startTime == "Afternoon" &&
              absence.endTime == "End of Day"
            ) {
              background =
                "linear-gradient(-45deg, " +
                color +
                ", " +
                color +
                " 49%, transparent 51%, transparent)";
            } else {
              background = color;
            }
          } else {
            if (
              absence.startDate.toDateString() == date.toDateString() &&
              absence.startTime == "Afternoon"
            ) {
              background =
                "linear-gradient(-45deg, " +
                color +
                ", " +
                color +
                " 49%, transparent 51%, transparent)";
            } else if (
              absence.endDate.toDateString() == date.toDateString() &&
              absence.endTime == "Lunchtime"
            ) {
              background =
                "linear-gradient(135deg, " +
                color +
                ", " +
                color +
                " 49%, transparent 51%, transparent)";
            } else {
              background = color;
            }
          }

          $(element)
            .parent()
            .click(() => {
              if (absence.type == "Public Holiday") {
                window.dispatchEvent(new CustomEvent(`holiday-${absence.id}`));
              } else {
                window.dispatchEvent(new CustomEvent(`absence-${absence.id}`));
              }
            });

          $(element)
            .parent()
            .css({
              background: background,
              opacity: absence.isApproved ? "1" : "0.5",
            });
        },
      });

      calendar.setYear(currentYear);
    },
  );
}

export function load_wallchart() {
  const calendars = document.querySelectorAll(".wallchart");
  const searchParams = new URLSearchParams(window.location.search);
  const date = new Date();
  const currentMonth =
    searchParams.get("month") == null
      ? date.getMonth()
      : parseInt(searchParams.get("month") || `${date.getMonth()}`) - 1;
  const currentYear = parseInt(
    searchParams.get("year") || `${date.getFullYear()}`,
  );

  $.getJSON(
    `/wfm/time-off/wallchart/users.json?year=${currentYear}`,
    ({ absences, users }) => {
      for (let i = 0; i < calendars.length; i++) {
        const dataLeaves: any[] = [];

        absences
          .filter(
            (absence: any) =>
              absence.user_id == (calendars[i] as HTMLElement).dataset.userId,
          )
          .forEach((absence: any) => {
            dataLeaves.push({
              id: absence.id,
              name: absence.leave_type.name,
              color: absence.leave_type.color,
              startDate: new Date(absence.start_date),
              startTime: absence.start_time,
              endDate: new Date(absence.end_date),
              endTime: absence.end_time,
              isApproved: absence.is_approved,
            });
          });

        const user = users.filter(
          (user: any) =>
            user.id == (calendars[i] as HTMLElement).dataset.userId,
        )[0];

        user.holidays.forEach((holiday: any) => {
          dataLeaves.push({
            id: holiday.id,
            type: "Public Holiday",
            name: holiday.name,
            startDate: new Date(holiday.observed),
            endDate: new Date(holiday.observed),
            color: "darkslateblue",
          });
        });

        if (user.birthday) {
          const birthdate = new Date(user.birthday);
          birthdate.setFullYear(currentYear);

          dataLeaves.push({
            type: "Birthday",
            name: "Birthday",
            startDate: new Date(birthdate),
            endDate: new Date(birthdate),
          });
        }

        const calendar = new Calendar(`#${calendars[i].id}`, {
          loadingTemplate: "",
          style: "custom",
          allowOverlap: false,
          enableRangeSelection: true,
          selectRange: (e) => {
            $('select[name="wallchart[selected_id]"]').val(
              (calendars[i] as HTMLElement).dataset.userId || "",
            );

            flatpickr('input[name="wallchart[start_date]"]', {
              defaultDate: e.startDate,
            });
            flatpickr('input[name="wallchart[end_date]"]', {
              defaultDate: e.endDate,
            });

            window.dispatchEvent(new CustomEvent("book"));
          },
          dataSource: dataLeaves,
          customDayRenderer: (element, date) => {
            if (date.toDateString() == new Date().toDateString()) {
              $(element).parent().addClass("today");
            }
          },
          customDataSourceRenderer: (element, date, absences) => {
            const absence = absences[0];
            const color = absence.color;
            let background;

            if (
              absence.startDate.toDateString() == date.toDateString() &&
              absence.endDate.toDateString() == date.toDateString()
            ) {
              if (absence.type == "Birthday") {
                element.setAttribute("hidden", "");
                element.insertAdjacentHTML(
                  "afterend",
                  `<svg class="h-4 w-4 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="currentColor"><path d="M448 384c-28.02 0-31.26-32-74.5-32-43.43 0-46.825 32-74.75 32-27.695 0-31.454-32-74.75-32-42.842 0-47.218 32-74.5 32-28.148 0-31.202-32-74.75-32-43.547 0-46.653 32-74.75 32v-80c0-26.5 21.5-48 48-48h16V112h64v144h64V112h64v144h64V112h64v144h16c26.5 0 48 21.5 48 48v80zm0 128H0v-96c43.356 0 46.767-32 74.75-32 27.951 0 31.253 32 74.75 32 42.843 0 47.217-32 74.5-32 28.148 0 31.201 32 74.75 32 43.357 0 46.767-32 74.75-32 27.488 0 31.252 32 74.5 32v96zM96 96c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40zm128 0c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40zm128 0c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40z"/></svg>`,
                );
              } else if (absence.type == "Public Holiday") {
                element.setAttribute("hidden", "");
                element.insertAdjacentHTML(
                  "afterend",
                  `<svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 mx-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>`,
                );
              } else if (
                absence.startTime == "Morning" &&
                absence.endTime == "Lunchtime"
              ) {
                background =
                  "linear-gradient(125deg, " +
                  color +
                  ", " +
                  color +
                  " 49%, transparent 51%, transparent)";
              } else if (
                absence.startTime == "Afternoon" &&
                absence.endTime == "End of Day"
              ) {
                background =
                  "linear-gradient(-55deg, " +
                  color +
                  ", " +
                  color +
                  " 49%, transparent 51%, transparent)";
              } else {
                background = color;
              }
            } else {
              if (
                absence.startDate.toDateString() == date.toDateString() &&
                absence.startTime == "Afternoon"
              ) {
                background =
                  "linear-gradient(-55deg, " +
                  color +
                  ", " +
                  color +
                  " 49%, transparent 51%, transparent)";
              } else if (
                absence.endDate.toDateString() == date.toDateString() &&
                absence.endTime == "Lunchtime"
              ) {
                background =
                  "linear-gradient(125deg, " +
                  color +
                  ", " +
                  color +
                  " 49%, transparent 51%, transparent)";
              } else {
                background = color;
              }
            }

            $(element)
              .parent()
              .click(() => {
                if (absence.type == "Public Holiday") {
                  window.dispatchEvent(
                    new CustomEvent(`holiday-${absence.id}`),
                  );
                } else {
                  window.dispatchEvent(
                    new CustomEvent(`absence-${absence.id}`),
                  );
                }
              });

            let tooltip = [
              '<div class="absolute left-10 ml-1 top-0 hidden group-hover:flex">',
              `<div class="absolute top-0 z-10 w-auto p-3 -mt-1 text-sm font-medium text-white transform -translate-x-1/2 -translate-y-full rounded-lg shadow-lg" style="background-color: ${color}">`,
              `<p>${absence.name}</p>`,
              "</div>",
              `<svg class="absolute z-10 w-6 h-6 transform -translate-x-10 -translate-y-3 fill-current stroke-current" width="8" height="8" color="${color}">`,
              '<rect x="12" y="-10" width="8" height="8" transform="rotate(45)" />',
              "</svg>",
              "</div>",
            ].join("\n");

            $(element)
              .parent()
              .addClass("relative group")
              .css({
                background: background,
                opacity: absence.type || absence.isApproved ? "1" : "0.5",
              })
              .append(tooltip);
          },
        });

        calendar.setYear(currentYear);
      }

      const months = document.querySelectorAll(".month-container");

      for (let i = 0; i < months.length; i++) {
        let month = parseInt((months[i] as HTMLElement).dataset.monthId || "0");

        if (month != currentMonth) {
          months[i].classList.add("hidden");
        }
      }
    },
  );
}

import Alpine from "alpinejs";

const bodyTag = document.querySelector("html");

document.addEventListener("alpine:init", () => {
  const getThemeFromLocalStorage = (): boolean => {
    if (window.localStorage.getItem("dark")) {
      return JSON.parse(window.localStorage.getItem("dark") || "false");
    }

    return (
      !!window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    );
  };

  const setThemeToLocalStorage = (value: boolean) => {
    window.localStorage.setItem("dark", value.toString());
  };

  const setTheme = (value: boolean) => {
    if (bodyTag) {
      if (value) {
        bodyTag.classList.add("dark");
      } else {
        bodyTag.classList.remove("dark");
      }
    }
  };

  const focusTrap = (element: any) => {
    const getFocusableElements = (element = document) => {
      return [
        ...element.querySelectorAll(
          'a, button, details, input, select, textarea, [tabindex]:not([tabindex="-1"])',
        ),
      ].filter((e) => !e.hasAttribute("disabled"));
    };

    const focusableElements = getFocusableElements(element);
    const firstFocusableEl = focusableElements[0];
    const lastFocusableEl = focusableElements[focusableElements.length - 1];

    setTimeout(() => (firstFocusableEl as HTMLElement).focus(), 50);

    const handleKeyDown = (e: any) => {
      const TAB = 9;
      const isTab = e.key.toLowerCase() === "tab" || e.keyCode === TAB;

      if (!isTab) return;

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableEl) {
          (lastFocusableEl as HTMLElement).focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableEl) {
          (firstFocusableEl as HTMLElement).focus();
          e.preventDefault();
        }
      }
    };

    element.addEventListener("keydown", handleKeyDown);

    return () => {
      element.removeEventListener("keydown", handleKeyDown);
    };
  };

  setTheme(getThemeFromLocalStorage());

  Alpine.data("data", () => ({
    dark: getThemeFromLocalStorage(),
    toggleTheme() {
      this.dark = !this.dark;
      setTheme(this.dark);
      setThemeToLocalStorage(this.dark);
    },
    isSideMenuOpen: false,
    toggleSideMenu() {
      this.isSideMenuOpen = !this.isSideMenuOpen;
    },
    closeSideMenu() {
      this.isSideMenuOpen = false;
    },
    isProfileMenuOpen: false,
    toggleProfileMenu() {
      this.isProfileMenuOpen = !this.isProfileMenuOpen;
    },
    closeProfileMenu() {
      this.isProfileMenuOpen = false;
    },
    isTimeOffMenuOpen: true,
    toggleTimeOffMenu() {
      this.isTimeOffMenuOpen = !this.isTimeOffMenuOpen;
    },
    isCheckInMenuOpen: false,
    toggleCheckInMenu() {
      this.isCheckInMenuOpen = !this.isCheckInMenuOpen;
    },
    isFilterMenuOpen: false,
    toggleFilterMenu() {
      this.isFilterMenuOpen = !this.isFilterMenuOpen;
    },
    closeFilterMenu() {
      this.isFilterMenuOpen = false;
    },
    // Modal
    isModalOpen: false,
    trapCleanup: <{ (): void } | null>null,
    modal() {
      return {
        isModalOpen: false,
        trapCleanup: null,
      };
    },
    openModal(element: any) {
      this.isModalOpen = true;
      this.trapCleanup = focusTrap(document.getElementById(element));
    },
    closeModal() {
      this.isModalOpen = false;
      this.trapCleanup = null;
    },
  }));
});

Alpine.start();
